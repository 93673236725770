@font-face {
	font-family: "oxanium";
	src: url("../../static/fonts/oxanium-v6-latin-regular.woff2") format("woff2");
}

@font-face {
	font-family: "VarelaRound";
	src: url("../../static/fonts/VarelaRound-Regular.ttf") format("truetype");
}

body {
	margin: 0;
	font-family: oxanium, VarelaRound, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Fix some addons integration taking pixels on RTL pages */
	width: 100vw;
	height: 100vh;
	position: absolute;
}

code {
	font-family: oxanium, VarelaRound, source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Disable ugly MS IE/Edge reveal password */
input::-ms-reveal,
input::-ms-clear {
	display: none;
}

.link {
	cursor: pointer;
}